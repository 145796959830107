
import { PropType } from "vue";
import { defineComponent } from "@vue/runtime-core";
import "../ui/styles.css";
export default defineComponent({
  emits: ["onClick"],
  props: {
    tags: {
      type: Array as PropType<{ title: string; active?: boolean }[]>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  methods:{
    navigateToTag(tagTitle) {
      const encodedTag = encodeURIComponent(tagTitle);
      const instagramUrl = `https://www.instagram.com/explore/search/keyword/?q=%23${encodedTag}`;
      window.open(instagramUrl, '_blank');
  }}
});
