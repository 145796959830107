import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createTextVNode as _createTextVNode, vModelText as _vModelText, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "modal-dialog" }
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "modal-header" }
const _hoisted_4 = { class: "modal-body" }
const _hoisted_5 = { class: "list-form" }
const _hoisted_6 = {
  key: 0,
  class: "text-center"
}
const _hoisted_7 = ["onUpdate:modelValue", "onInput"]
const _hoisted_8 = { class: "new-list" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "btn-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["modal list-model", { show: _ctx.show }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[5] || (_cache[5] = _createElementVNode("h6", null, "Add to list", -1)),
          _createElementVNode("button", {
            type: "button",
            class: "close",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)))
          }, "×")
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_ctx.lists.length === 0)
              ? (_openBlock(), _createElementBlock("p", _hoisted_6, "No List To Show"))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lists, (list) => {
              return (_openBlock(), _createElementBlock("label", {
                key: list.title,
                class: "list-cont"
              }, [
                _createTextVNode(_toDisplayString(list.title) + " ", 1),
                _withDirectives(_createElementVNode("input", {
                  type: "checkbox",
                  "onUpdate:modelValue": ($event: any) => ((list.selected) = $event),
                  onInput: ($event: any) => (_ctx.toggleListTopic(list.id, list.selected == true))
                }, null, 40, _hoisted_7), [
                  [_vModelCheckbox, list.selected]
                ]),
                _cache[6] || (_cache[6] = _createElementVNode("span", { class: "checkmark" }, null, -1))
              ]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_8, [
            (_ctx.isNewList)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _cache[7] || (_cache[7] = _createElementVNode("h6", null, "New List", -1)),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.title) = $event)),
                    placeholder: "Title",
                    class: "form-control"
                  }, null, 512), [
                    [_vModelText, _ctx.title]
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    (!_ctx.isLoading)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          class: "listcancel cmn-accord-btn",
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleNewList()))
                        }, " Cancel "))
                      : _createCommentVNode("", true),
                    (!_ctx.isLoading)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 1,
                          class: "listcreate cmn-accord-btn",
                          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.createNewList()))
                        }, " Create "))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true),
            (!_ctx.isNewList)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  class: "new-list-btn",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.toggleNewList()))
                }, " + Create New List "))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ])
  ], 2))
}