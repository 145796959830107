import { createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '../../assets/images/blog-access_time.svg'


const _hoisted_1 = { class: "article_card mb-4" }
const _hoisted_2 = {
  key: 0,
  class: "article_img"
}
const _hoisted_3 = ["href"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "article-cont" }
const _hoisted_6 = {
  key: 0,
  class: "badge mb-1 d-inline-block"
}
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "text-secandary d-block mb-1" }
const _hoisted_9 = { class: "badge durationBadge mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.thumbnail)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("a", {
            target: "_blank",
            href: _ctx.link,
            ref: "noopener nofollow"
          }, [
            _createElementVNode("img", {
              src: _ctx.thumbnail,
              alt: "",
              class: "img-fluid",
              onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.loadImage && _ctx.loadImage(...args)))
            }, null, 40, _hoisted_4),
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "play_btn" }, [
              _createElementVNode("i", { class: "fa fa-play" })
            ], -1))
          ], 8, _hoisted_3)
        ], 512)), [
          [_vShow, _ctx.isLoaded]
        ])
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.isNews)
        ? (_openBlock(), _createElementBlock("span", _hoisted_6, "News"))
        : _createCommentVNode("", true),
      _createElementVNode("a", {
        href: _ctx.link,
        target: "_blank",
        ref: "noopener nofollow"
      }, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1)
      ], 8, _hoisted_7),
      _createElementVNode("small", _hoisted_8, _toDisplayString(_ctx.displayed_link), 1),
      _createElementVNode("span", _hoisted_9, [
        _cache[2] || (_cache[2] = _createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }, null, -1)),
        _createTextVNode(" " + _toDisplayString(_ctx.duration), 1)
      ]),
      _createElementVNode("p", null, _toDisplayString(_ctx.snippet), 1)
    ])
  ]))
}