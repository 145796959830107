import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "side_bar" }
const _hoisted_2 = { class: "side_card" }
const _hoisted_3 = { class: "tags" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "sairaR" }, "Instagram Tags", -1)),
      _createElementVNode("ul", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tag) => {
          return (_openBlock(), _createElementBlock("li", {
            class: "tag_block",
            key: tag.title
          }, [
            _createElementVNode("span", {
              class: "badge social-badge",
              onClick: ($event: any) => (_ctx.navigateToTag(tag.title))
            }, "#" + _toDisplayString(tag.title), 9, _hoisted_4)
          ]))
        }), 128))
      ])
    ])
  ]))
}