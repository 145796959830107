
import { defineComponent, PropType } from "vue";
import SearchArticleCard from "@/components/topic/SearchArticleCard.vue";
import {
  SearchResultArticle,
  SearchKeyword,
  SearchKeywordType,
} from "@toppick/common/build/interfaces";
import { getSearchResultsArticle } from "@toppick/common/build/api/search";
import Pagination from "@/components/ui/Pagination.vue";
import SearchKeywords from "@/components/topic/SearchKeywords.vue";
import { getAuthToken } from "@/utils/auth";

export default defineComponent({

  components: { SearchArticleCard, Pagination, SearchKeywords },
  props: {
    topicId: {
      type: Number,
      required: true,
    },
    keywords: {
      type: Array as PropType<SearchKeyword[]>,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      articles: [] as SearchResultArticle[],
    };
  },

  methods: {
    async loadData() {
      this.isLoading = true;
      this.articles = await getSearchResultsArticle(await getAuthToken(), {
        keyword_id: this.currentKeyword!.id,
      });
      this.isLoading = false;
    },
    onChangeKeyword(index: number) {
      this.$router.replace({
        query: { keyword: this.keywords[index].title },
      });
    },
  },

  computed: {
    totalPages() {
      return this.keywords.length;
    },
    currentPage(): number {
      return (
        this.keywords.findIndex((k) => k.id === this.currentKeyword!.id) + 1
      );
    },
    currentKeyword(): SearchKeyword | undefined {
      if (this.keywords.length <= 0) return undefined;
      return (
        this.keywords.find((k) => k.title === this.$route.query.keyword) ||
        this.keywords[0]
      );
    },

    isNews() {
      if (!this.currentKeyword) return false;
      return this.currentKeyword.keyword_type === SearchKeywordType.News;
    },
  },

  async mounted() {
    this.$watch(
      () => ({
        keyword: this.currentKeyword,
      }),
      async () => {
        try {
          await this.loadData();
        } catch (error) {
          console.log(error);
        }
      },
      { immediate: true }
    );
  },
});
