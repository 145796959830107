import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/camera-white-small.svg'
import _imports_1 from '@/assets/images/image-gallery.svg'


const _hoisted_1 = {
  key: 0,
  class: "body_wrap topic_page cmnBody_wrap"
}
const _hoisted_2 = { class: "space" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-md-8" }
const _hoisted_6 = { class: "topic_area" }
const _hoisted_7 = { class: "topic_tabs" }
const _hoisted_8 = ["src"]
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "col-md-4" }
const _hoisted_11 = { class: "side_bar download_sidebar bg-white" }
const _hoisted_12 = { class: "side_card" }
const _hoisted_13 = { class: "download_cont_block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_section = _resolveComponent("header-section")!
  const _component_bar = _resolveComponent("bar")!
  const _component_actions_section = _resolveComponent("actions-section")!
  const _component_questions_section = _resolveComponent("questions-section")!
  const _component_search_articles_section = _resolveComponent("search-articles-section")!
  const _component_search_videos_section = _resolveComponent("search-videos-section")!
  const _component_search_images_section = _resolveComponent("search-images-section")!
  const _component_side_bar = _resolveComponent("side-bar")!
  const _component_tags_bar = _resolveComponent("tags-bar")!
  const _component_side_bar_share_icons = _resolveComponent("side-bar-share-icons")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_auth_modal = _resolveComponent("auth-modal")!
  const _component_loader = _resolveComponent("loader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.topic)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_header_section, {
            image: _ctx.topic.image,
            title: _ctx.topic.title,
            description: _ctx.topic.description
          }, null, 8, ["image", "title", "description"]),
          _createElementVNode("section", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_bar, {
                      level: _ctx.topic.level,
                      source: _ctx.topic.source,
                      title: _ctx.topic.title
                    }, null, 8, ["level", "source", "title"]),
                    _createVNode(_component_actions_section, {
                      onOnQuestionCreate: _ctx.onQuestionCreate,
                      onOnShowAuthModal: _ctx.toggleAuthModal,
                      topic_id: _ctx.topic.id
                    }, null, 8, ["onOnQuestionCreate", "onOnShowAuthModal", "topic_id"]),
                    _createElementVNode("div", _hoisted_7, [
                      ((_ctx.topic?.questionCount ?? 0) > 0)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setTab(_ctx.TopicPageTab.QUESTION))),
                            class: _normalizeClass({
                    active: _ctx.currentTab === _ctx.TopicPageTab.QUESTION,
                  })
                          }, [
                            _createElementVNode("img", {
                              src: _ctx.questionButtonIcon,
                              alt: "",
                              class: "mr-2"
                            }, null, 8, _hoisted_8),
                            _cache[4] || (_cache[4] = _createTextVNode(" Questions "))
                          ], 2))
                        : _createCommentVNode("", true),
                      (_ctx.topic.keywordsArticle && _ctx.topic.keywordsArticle.length > 0)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 1,
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setTab(_ctx.TopicPageTab.ARTICLE))),
                            class: _normalizeClass({
                    active: _ctx.currentTab === _ctx.TopicPageTab.ARTICLE,
                  })
                          }, [
                            _createElementVNode("img", {
                              src: _ctx.articleButtonIcon,
                              alt: "",
                              class: "mr-2"
                            }, null, 8, _hoisted_9),
                            _cache[5] || (_cache[5] = _createTextVNode("Articles "))
                          ], 2))
                        : _createCommentVNode("", true),
                      (_ctx.topic.keywordsVideo && _ctx.topic.keywordsVideo.length > 0)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 2,
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setTab(_ctx.TopicPageTab.VIDEO))),
                            class: _normalizeClass({
                    active: _ctx.currentTab === _ctx.TopicPageTab.VIDEO,
                  })
                          }, _cache[6] || (_cache[6] = [
                            _createElementVNode("img", {
                              src: _imports_0,
                              alt: "",
                              class: "mr-2"
                            }, null, -1),
                            _createTextVNode("Videos ")
                          ]), 2))
                        : _createCommentVNode("", true),
                      (_ctx.topic.keywordsImage && _ctx.topic.keywordsImage.length > 0)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 3,
                            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setTab(_ctx.TopicPageTab.IMAGE))),
                            class: _normalizeClass({
                    active: _ctx.currentTab === _ctx.TopicPageTab.IMAGE,
                  })
                          }, _cache[7] || (_cache[7] = [
                            _createElementVNode("img", {
                              src: _imports_1,
                              alt: "",
                              class: "mr-2"
                            }, null, -1),
                            _createTextVNode("Images ")
                          ]), 2))
                        : _createCommentVNode("", true)
                    ]),
                    (_ctx.currentTab === _ctx.TopicPageTab.QUESTION)
                      ? (_openBlock(), _createBlock(_component_questions_section, {
                          key: 0,
                          questionCount: _ctx.topic.questionCount ?? 0,
                          onOnShowAuthModal: _ctx.toggleAuthModal,
                          topicId: _ctx.topic.id
                        }, null, 8, ["questionCount", "onOnShowAuthModal", "topicId"]))
                      : _createCommentVNode("", true),
                    (_ctx.currentTab === _ctx.TopicPageTab.ARTICLE)
                      ? (_openBlock(), _createBlock(_component_search_articles_section, {
                          key: 1,
                          topicId: _ctx.topic.id,
                          keywords: _ctx.topic.keywordsArticle ?? []
                        }, null, 8, ["topicId", "keywords"]))
                      : _createCommentVNode("", true),
                    (_ctx.currentTab === _ctx.TopicPageTab.VIDEO)
                      ? (_openBlock(), _createBlock(_component_search_videos_section, {
                          key: 2,
                          topicId: _ctx.topic.id,
                          keywords: _ctx.topic.keywordsVideo ?? []
                        }, null, 8, ["topicId", "keywords"]))
                      : _createCommentVNode("", true),
                    (_ctx.currentTab === _ctx.TopicPageTab.IMAGE)
                      ? (_openBlock(), _createBlock(_component_search_images_section, {
                          key: 3,
                          topicId: _ctx.topic.id,
                          keywords: _ctx.topic.keywordsImage ?? []
                        }, null, 8, ["topicId", "keywords"]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_side_bar, {
                    onOnClick: _ctx.onClickCategory,
                    items: _ctx.relatedCategories,
                    title: "Related Categories",
                    type: "Category",
                    maxItems: 5
                  }, null, 8, ["onOnClick", "items"]),
                  _createVNode(_component_side_bar, {
                    onOnClick: _ctx.onClickTopic,
                    items: _ctx.relatedTopics,
                    title: "Related Topics",
                    maxItems: 5,
                    type: "Topic"
                  }, null, 8, ["onOnClick", "items"]),
                  (_ctx.topic.topic_social_tags && _ctx.topic.topic_social_tags.length > 0)
                    ? (_openBlock(), _createBlock(_component_tags_bar, {
                        key: 0,
                        tags: _ctx.topic.topic_social_tags ?? []
                      }, null, 8, ["tags"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_side_bar_share_icons, {
                    hashtags: "TopPick",
                    quote: 'Check out this awesome topic topic ' + _ctx.topic.title,
                    url: _ctx.currentPath,
                    title: _ctx.topic.title,
                    description: _ctx.topic.description
                  }, null, 8, ["quote", "url", "title", "description"]),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _cache[10] || (_cache[10] = _createElementVNode("h3", { class: "sairaR" }, "Suggestions", -1)),
                      _createElementVNode("div", _hoisted_13, [
                        _cache[9] || (_cache[9] = _createElementVNode("p", null, "We are always looking for new topic inspirations.", -1)),
                        _createVNode(_component_router_link, {
                          to: { name: 'ContactUs' },
                          class: "cm-btn cm_bg text-white"
                        }, {
                          default: _withCtx(() => _cache[8] || (_cache[8] = [
                            _createTextVNode("Suggest a topic")
                          ])),
                          _: 1
                        })
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_auth_modal, {
      title: "Login or Create to unlock this functionality",
      show: _ctx.isShowAuthModal,
      onOnClose: _ctx.toggleAuthModal
    }, null, 8, ["show", "onOnClose"]),
    _createVNode(_component_loader, { show: _ctx.isLoading }, null, 8, ["show"])
  ], 64))
}