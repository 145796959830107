
import { defineComponent } from "vue";
import { getTopicDetails } from "@toppick/common/build/api/topic";
import HeaderSection from "./HeaderSection.vue";
import Bar from "./Bar.vue";
import SideBar from "@/components/ui/SideBar.vue";
import { Question, Topic } from "@toppick/common/build/interfaces";
import { SideBarItem, TopicPageTab } from "@/interfaces/ui";
import QuestionsSection from "./QuestionsSection.vue";
import { SearchResultArticle } from "@toppick/common/build/interfaces";
import SearchArticlesSection from "./SearchArticlesSection.vue";
import Loader from "@/components/ui/Loader.vue";
import SearchVideosSection from "./SearchVideosSection.vue";
import SearchImagesSection from "./SearchImagesSection.vue";
import ActionsSection from "./ActionsSection.vue";
import SideBarShareIcons from "@/components/topic/SideBarShareIcons.vue";
import TagsBar from "@/components/ui/TagsBar.vue";
import AuthModal from "@/components/auth/AuthModal.vue";
import { isValidTab } from "@/utils/ui";
import "./styles.css";
import { getAuthToken } from "@/utils/auth";

export default defineComponent({
  components: {
    HeaderSection,
    Bar,
    SideBar,
    QuestionsSection,
    SearchArticlesSection,
    Loader,
    SearchVideosSection,
    SideBarShareIcons,
    SearchImagesSection,
    TagsBar,
    AuthModal,
    ActionsSection,
  },
  data() {
    return {
      isLoading: true,
      topic: null as Topic | null,
      articles: [] as SearchResultArticle[],
      questionButtonIcon: require("@/assets/images/question-btn-icon.svg"),
      articleButtonIcon: require("@/assets/images/article-btn-icon-white.svg"),
      videoButtonIcon: require("@/assets/images/camera-white.svg"),
      TopicPageTab,
      isShowAuthModal: false,
    };
  },
  methods: {
    toggleAuthModal() {
      this.isShowAuthModal = !this.isShowAuthModal;
    },

    setTab(tab: TopicPageTab) {
      this.$router.replace({
        params: { tab },
        query: { page: 1 },
      });
    },

    onClickTopic(slug: string) {
      let routeData = this.$router.resolve({
        name: "Topic",
        params: { slug, tab: TopicPageTab.QUESTION },
        query: { page: 1 },
      });
      window.open(routeData.href, "_blank");
    },

    onClickCategory(slug: string) {
      let routeData = this.$router.resolve({
        name: "Category",
        params: { slug },
      });
      window.open(routeData.href, "_blank");
    },

    onQuestionCreate(question: Question) {
      this.eventBus.emit("onQuestionAdd", {
        question,
      });
    },

    async loadData() {
      this.topic = await getTopicDetails(await getAuthToken(), {
        slug: this.currentSlug,
        include_search_kewyords: true,
        count_questions: true,
        include_related_topics: true,
        include_categories: true,
        include_social_tags: true,
      });
    },
  },
  computed: {
    relatedCategories() {
      const items: SideBarItem[] = [];
      if (this.topic) {
        this.topic.topics_categories!.forEach((top_cat) => {
          items.push(top_cat.categories);
        });
      }
      return items;
    },

    currentPath() {
      return window.location.href;
    },
    currentTab(): string {
      return this.$route.params.tab as string;
    },
    currentSlug(): string {
      return this.$route.params.slug as string;
    },
    relatedTopics() {
      const items: SideBarItem[] = [];
      if (this.topic) {
        this.topic.topics_related_topics_related_source_idTotopics!.forEach(
          (rel) => {
            items.push(rel.topics_topics_related_dest_idTotopics);
          }
        );
      }
      return items;
    },
  },

  async mounted() {
    if (!isValidTab(TopicPageTab, this.$route.params.tab)) {
      this.setTab(TopicPageTab.QUESTION);
    }

    this.$watch(
      () => this.currentSlug,
      async () => {
        this.isLoading = true;
        try {
          if (this.currentSlug) {
            await this.loadData();
          }
        } catch (error) {
          console.error(error);
        }
        this.isLoading = false;
      },
      { immediate: true }
    );
  },
});
