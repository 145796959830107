import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "card-header" }
const _hoisted_2 = { class: "mb-0" }
const _hoisted_3 = { class: "topAccord_area" }
const _hoisted_4 = { class: "cmn_topic_heading" }
const _hoisted_5 = { class: "cmn_topic_heading_img" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "accord_hover_icons" }
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 0,
  class: "copied_message"
}
const _hoisted_10 = {
  key: 0,
  class: "card-body"
}
const _hoisted_11 = {
  key: 0,
  class: "report_msg"
}
const _hoisted_12 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_guide_tool_tip = _resolveComponent("guide-tool-tip")!
  const _component_edit_input = _resolveComponent("edit-input")!
  const _component_status_badge = _resolveComponent("status-badge")!
  const _component_question_menu = _resolveComponent("question-menu")!
  const _component_answer_section = _resolveComponent("answer-section")!
  const _component_resource_section = _resolveComponent("resource-section")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass('card ' + _ctx.cardBackground)
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h5", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("p", _hoisted_4, [
              _createElementVNode("span", _hoisted_5, [
                _createElementVNode("img", {
                  src: _ctx.questionHeadingIcon,
                  alt: ""
                }, null, 8, _hoisted_6),
                _createVNode(_component_guide_tool_tip, {
                  slug: "create-a-good-question",
                  text: "Discover more"
                })
              ]),
              _createTextVNode(" " + _toDisplayString(_ctx.label), 1)
            ])
          ]),
          _createElementVNode("button", {
            class: _normalizeClass(["btn btn-link", { collapsed: !_ctx.isOpen }]),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleCard()))
          }, [
            _createVNode(_component_edit_input, {
              isEditing: _ctx.isEditing,
              editable: false,
              error: _ctx.error,
              showAvatar: _ctx.isOpen,
              isLoading: _ctx.isQuestionLoading,
              modelValue: _ctx.currentQuestion.title,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentQuestion.title) = $event)),
              backgroundClass: _ctx.backgroundClass,
              placeholder: "Edit Question",
              onOnClose: _ctx.onCloseQuestionEditField,
              onOnSubmit: _ctx.onUpdateQuestion,
              onOnEdit: _ctx.onEdit,
              user: _ctx.currentQuestion.users,
              onOnDelete: _ctx.onDeleteQuestion,
              showDelete: false,
              inputFieldId: _ctx.questionTextInputId,
              created_by_creator: _ctx.currentQuestion.created_by_creator
            }, null, 8, ["isEditing", "error", "showAvatar", "isLoading", "modelValue", "backgroundClass", "onOnClose", "onOnSubmit", "onOnEdit", "user", "onOnDelete", "inputFieldId", "created_by_creator"])
          ], 2),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_status_badge, {
              status: _ctx.currentQuestion.status,
              user_id: _ctx.currentQuestion.user_id
            }, null, 8, ["status", "user_id"]),
            _createElementVNode("button", {
              class: "copy",
              style: {"cursor":"pointer"},
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onCopy()))
            }, [
              _createElementVNode("img", {
                src: _ctx.copyIcon,
                alt: ""
              }, null, 8, _hoisted_8),
              (_ctx.copyModal)
                ? (_openBlock(), _createElementBlock("span", _hoisted_9, "Copied!"))
                : _createCommentVNode("", true)
            ]),
            (_ctx.isOpen && !_ctx.isLoading)
              ? (_openBlock(), _createBlock(_component_question_menu, {
                  key: 0,
                  userId: _ctx.currentQuestion.user_id,
                  onOnDelete: _ctx.onDeleteQuestion,
                  question_id: _ctx.currentQuestion.id,
                  onOnEdit: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onEdit())),
                  onShowReport: _ctx.showReport
                }, null, 8, ["userId", "onOnDelete", "question_id", "onShowReport"]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["card_collapse", { show: _ctx.isOpen, collapse: !_ctx.isOpen }])
      }, [
        (_ctx.isOpen && !_ctx.isLoading)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_ctx.isOpen)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createVNode(_component_answer_section, {
                      answers: _ctx.currentQuestion.answers || [],
                      questionId: _ctx.question.id,
                      answersCount: _ctx.currentQuestion.answer_count || 0,
                      answerValidate: _ctx.currentQuestion.answer_validate,
                      showTranslation: _ctx.isShowTranslation,
                      onOnShowAuthModal: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('onShowAuthModal')))
                    }, null, 8, ["answers", "questionId", "answersCount", "answerValidate", "showTranslation"]),
                    _createVNode(_component_resource_section, {
                      resources: _ctx.currentQuestion.resources || [],
                      questionId: _ctx.question.id,
                      resourcesCount: _ctx.currentQuestion.resource_count || 0,
                      resource_validate: _ctx.currentQuestion.resource_validate,
                      onOnShowAuthModal: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('onShowAuthModal')))
                    }, null, 8, ["resources", "questionId", "resourcesCount", "resource_validate"]),
                    (_ctx.isShowReport)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_11, " Thanks for your feedback. "))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true)
      ], 2)
    ], 2),
    (_ctx.showUndoButton)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "undo_btn",
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.resetDelete()))
        }, [
          _createElementVNode("img", {
            src: _ctx.undoIcon,
            alt: "Undo Icon"
          }, null, 8, _hoisted_12),
          _cache[7] || (_cache[7] = _createTextVNode(" Click here to undo "))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}