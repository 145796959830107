import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/question-heading-icon-white.svg'
import _imports_1 from '@/assets/images/list-white.svg'
import _imports_2 from '@/assets/images/plus.svg'


const _hoisted_1 = { class: "dropdown-topic" }
const _hoisted_2 = { class: "question-drop" }
const _hoisted_3 = { class: "inner-dropdown" }
const _hoisted_4 = { class: "add_question" }
const _hoisted_5 = { class: "add_btn add-question-btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_question_submit_input = _resolveComponent("question-submit-input")!
  const _component_list_modal = _resolveComponent("list-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["add_input_area_dropdown mb-3", { show: _ctx.isShowMenu }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("ul", _hoisted_3, [
            _createElementVNode("li", {
              class: "inner-dropdown-item",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleShowAddQuestion()))
            }, _cache[4] || (_cache[4] = [
              _createElementVNode("span", { style: {"padding-right":"20px"} }, "Submit a question", -1),
              _createElementVNode("span", { class: "add-img" }, [
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: ""
                })
              ], -1)
            ])),
            _createElementVNode("li", {
              class: "inner-dropdown-item",
              "data-source": "addlist",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleShowListModal()))
            }, _cache[5] || (_cache[5] = [
              _createElementVNode("span", null, "Add to list ", -1),
              _createElementVNode("span", { class: "add-img" }, [
                _createElementVNode("img", {
                  src: _imports_1,
                  alt: ""
                })
              ], -1)
            ]))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_question_submit_input, {
          onOnQuestionCreate: _ctx.onQuestionCreate,
          onOnQuestionClose: _ctx.toggleShowAddQuestion,
          topic_id: _ctx.topic_id,
          show: _ctx.isShowAddQuestion
        }, null, 8, ["onOnQuestionCreate", "onOnQuestionClose", "topic_id", "show"])
      ]),
      _createVNode(_component_list_modal, {
        show: _ctx.isShowListModal,
        onOnClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleShowListModal())),
        topic_id: _ctx.topic_id
      }, null, 8, ["show", "topic_id"])
    ], 2),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("button", {
        class: "cmn_topic_heading",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setShowMenu(!_ctx.isShowMenu)))
      }, _cache[6] || (_cache[6] = [
        _createElementVNode("img", {
          src: _imports_2,
          alt: "Create Icon"
        }, null, -1)
      ]))
    ])
  ], 64))
}