import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "side_bar" }
const _hoisted_2 = { class: "side_card" }
const _hoisted_3 = { class: "sairaR" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "card_list" }
const _hoisted_7 = { class: "card_list_img" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "card_list_text" }
const _hoisted_10 = { class: "post_type" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.title), 1),
      (_ctx.items)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: item.slug,
                class: _normalizeClass({ hideList_item: !_ctx.isOpen && index >= _ctx.maxItems }),
                onClick: ($event: any) => (_ctx.onClick(item.slug))
              }, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("img", {
                      src: item.image,
                      alt: ""
                    }, null, 8, _hoisted_8)
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("p", null, _toDisplayString(item.title), 1),
                    _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.type), 1)
                  ])
                ])
              ], 10, _hoisted_5))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (_ctx.items.length > _ctx.maxItems)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (!_ctx.isOpen)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  type: "button",
                  class: "see_more sairaM",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleMenu()))
                }, _cache[2] || (_cache[2] = [
                  _createTextVNode(" See more"),
                  _createElementVNode("i", { class: "fa fa-angle-down ml-2" }, null, -1)
                ])))
              : (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  type: "button",
                  class: "see_more seeLess sairaM",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleMenu()))
                }, _cache[3] || (_cache[3] = [
                  _createTextVNode(" See less"),
                  _createElementVNode("i", { class: "fa fa-angle-up ml-2" }, null, -1)
                ])))
          ], 64))
        : _createCommentVNode("", true)
    ])
  ]))
}