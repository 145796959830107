
import { getAuthToken } from "@/utils/auth";
import {
  addTopicToUserList,
  createTopicList,
  getTopicLists,
  getTopicListsIdsByTopicId,
  removeTopicFromUserList,
} from "@toppick/common/build/api";
import { TopicList, TopicListFeatured } from "@toppick/common/build/interfaces";
import { validateListName } from "@toppick/common/build/validators";
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  emits: ["onClose"],
  props: {
    show: Boolean,
    topic_id: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      isNewList: false,
      title: "",
      error: false,
      isLoading: false,
      lists: [] as TopicListFeatured[],
    };
  },
  methods: {
    async loadData() {
      const lists = await getTopicLists(
        await getAuthToken(),
        this.$store.getters.getUserID
      );
      const selectedLists = await getTopicListsIdsByTopicId(
        await getAuthToken(),
        this.$store.getters.getUserID,
        this.topic_id
      );
      selectedLists.forEach((selectedList) => {
        const index = lists.findIndex(
          (list) => list.id === selectedList.list_id
        );
        lists[index].selected = true;
      });
      this.lists = lists;
    },
    toggleNewList() {
      this.isNewList = !this.isNewList;
    },

    async toggleListTopic(id: number, oldValue: boolean) {
      const newValue = !oldValue;
      try {
        if (newValue) {
          await addTopicToUserList(
            await getAuthToken(),
            this.$store.getters.getUserID,
            id,
            this.topic_id
          );
        } else {
          await removeTopicFromUserList(
            await getAuthToken(),
            this.$store.getters.getUserID,
            id,
            this.topic_id
          );
        }
        const newList = [...this.lists];
        const index = newList.findIndex((list) => list.id === id);
        newList[index].selected = newValue;
        this.lists = newList;
      } catch (error) {
        console.log(error);
      }
    },

    async createNewList() {
      this.error = false;
      this.isLoading = true;
      try {
        if (!validateListName(this.title)) {
          throw new Error("Invalid List");
        }
        const createdList = await createTopicList(
          await getAuthToken(),
          this.$store.getters.getUserID,
          { title: this.title }
        );
        this.lists = [...this.lists, { ...createdList, selected: true }];
        this.isNewList = false;
        this.title = "";
      } catch (error) {
        this.error = true;
      }
      this.isLoading = false;
    },
    onClose() {
      this.$emit("onClose");
    },
  },

  mounted: async function () {
    this.$watch(
      () => ({
        topic_id: this.topic_id,
      }),
      async () => {
        if (this.$store.getters.getIsAuthenticated) await this.loadData();
      },
      { immediate: true }
    );
  },
});
