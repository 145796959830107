import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "article" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_keywords = _resolveComponent("search-keywords")!
  const _component_search_article_card = _resolveComponent("search-article-card")!
  const _component_pagination = _resolveComponent("pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_search_keywords, {
      keywords: _ctx.keywords,
      activeKeyword: _ctx.currentKeyword,
      onOnChangeKeyword: _ctx.onChangeKeyword
    }, null, 8, ["keywords", "activeKeyword", "onOnChangeKeyword"]),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.articles, (article) => {
        return (_openBlock(), _createBlock(_component_search_article_card, {
          key: article.id,
          title: article.title,
          link: article.link,
          thumbnail: article.thumbnail,
          snippet: article.snippet,
          isNews: _ctx.isNews
        }, null, 8, ["title", "link", "thumbnail", "snippet", "isNews"]))
      }), 128))
    ]),
    _createVNode(_component_pagination, {
      currentPage: _ctx.currentPage,
      totalPages: _ctx.totalPages,
      onOnChangePage: _cache[0] || (_cache[0] = (page) => _ctx.onChangeKeyword(--page))
    }, null, 8, ["currentPage", "totalPages"])
  ], 64))
}