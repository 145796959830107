
import Multiselect from "@vueform/multiselect";
import { timeout } from "@/utils/utils";
import { defineComponent } from "@vue/runtime-core";
import { v4 as uuidv4 } from "uuid";
import { MutationTypes } from "@/store";
import { DEFAULT_LANGUAGE } from "../../store/state";

const LANGUAGES = [
  { value: "af", label: "Afrikaans" },
  { value: "sq", label: "Albanian" },
  { value: "ar", label: "Arabic" },
  { value: "az", label: "Azerbaijani" },
  { value: "bn", label: "Bengali" },
  { value: "bs", label: "Bosnian" },
  { value: "zh-CN", label: "Chinese (Simplified)" },
  { value: "zh-TW", label: "Chinese (Traditional)" },
  { value: "hr", label: "Croatian" },
  { value: "cs", label: "Czech" },
  { value: "da", label: "Danish" },
  { value: "nl", label: "Dutch" },
  { value: "eo", label: "Esperanto" },
  { value: "et", label: "Estonian" },
  { value: "tl", label: "Filipino" },
  { value: "fi", label: "Finnish" },
  { value: "fr", label: "French" },
  { value: "ka", label: "Georgian" },
  { value: "de", label: "German" },
  { value: "el", label: "Greek" },
  { value: "haw", label: "Hawaiian" },
  { value: "iw", label: "Hebrew" },
  { value: "hi", label: "Hindi" },
  { value: "hu", label: "Hungarian" },
  { value: "is", label: "Icelandic" },
  { value: "ig", label: "Igbo" },
  { value: "id", label: "Indonesian" },
  { value: "ga", label: "Irish" },
  { value: "it", label: "Italian" },
  { value: "ja", label: "Japanese" },
  { value: "ku", label: "Kurdish" },
  { value: "mk", label: "Macedonian" },
  { value: "no", label: "Norwegian" },
  { value: "fa", label: "Persian" },
  { value: "pl", label: "Polish" },
  { value: "pt", label: "Portuguese" },
  { value: "pt", label: "Portuguese" },
  { value: "pa", label: "Punjabi" },
  { value: "ro", label: "Romanian" },
  { value: "ru", label: "Russian" },
  { value: "sa", label: "Sanskrit" },
  { value: "sr", label: "Serbian" },
  { value: "sk", label: "Slovak" },
  { value: "sl", label: "Slovenian" },
  { value: "es", label: "Spanish" },
  { value: "sw", label: "Swahili" },
  { value: "ta", label: "Tamil" },
  { value: "th", label: "Thai" },
  { value: "tr", label: "Turkish" },
  { value: "tk", label: "Turkmen" },
  { value: "uk", label: "Ukrainian" },
  { value: "ur", label: "Urdu" },
  { value: "uz", label: "Uzbek" },
  { value: "vi", label: "Vietnamese" },
];

export default defineComponent({
  components: { Multiselect },
  data() {
    return {
      isLoading: true,
      translateElementId: uuidv4(),
      currentLanguage: null as string | null,
      languages: LANGUAGES,
      googleSelectElement: null as any
    };
  },
  methods: {
    async initGoogleTranslator() {
      this.loadGoogleTranslateScript();
      await this.googleTranslateElementInit();
      await this.waitUntilGoogleTranslatorIsReady();
    },
    googleTranslateElementInit() {
      return new Promise<void>((resolve) => {
        const interval = setInterval(() => {
          if (window.google && window.google.translate) {
            try {
              new window.google.translate.TranslateElement(
                {
                  pageLanguage: this.currentLanguage,
                },
                this.translateElementId
              );
              clearInterval(interval);
              resolve();
            } catch (error) {
              console.error(error);
            }
          }
        }, 1000);
      });
    },
    loadGoogleTranslateScript() {
      const plugin = document.createElement("script");
      plugin.setAttribute(
        "src",
        "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
      );
      plugin.async = true;
      document.head.appendChild(plugin);
    },
    async waitUntilGoogleTranslatorIsReady(): Promise<void> {
      await timeout(1000);
      const googleSelectElement = document.getElementsByClassName(
        "goog-te-combo"
      )[0] as any;
      if (googleSelectElement && googleSelectElement.length > 0) {
        this.googleSelectElement = googleSelectElement;
      } else {
        return await this.waitUntilGoogleTranslatorIsReady();
      }
    },
    async onChangeLanguage(newLanguage: string) {
      this.currentLanguage = newLanguage;
      if (!newLanguage) {
        newLanguage = DEFAULT_LANGUAGE;
      }
      if (this.googleSelectElement) {
        this.$store.commit(MutationTypes.SET_CONTENT_LANGUAGE, newLanguage);
        this.googleSelectElement.value = newLanguage;
        this.googleSelectElement.dispatchEvent(new Event("change"));
        localStorage.setItem("contentLanguage", newLanguage);
      }
    },
  getStorageTranslateLanguage(): string | null {
    const storedLanguage = localStorage.getItem("contentLanguage") as string;
    if (
      LANGUAGES.find((l) => l.value === storedLanguage) &&
      storedLanguage !== DEFAULT_LANGUAGE
    ) {
      return storedLanguage;
    }
    return null;
  },
  async forceInitialTranslateRefresh() {
    if (this.$refs.multiselect && this.currentLanguage) {
      (this.$refs.multiselect as any).activate();
      await timeout(500);
      (this.$refs.multiselect as any).deactivate();
      this.onChangeLanguage(this.currentLanguage);
    }
  },
},
  async mounted() {
    await this.initGoogleTranslator();
    const storedLanguage = this.getStorageTranslateLanguage();
    if (storedLanguage) {
      this.currentLanguage = storedLanguage;
      await this.forceInitialTranslateRefresh();
    }
    this.isLoading = false;
  },
});
