import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "translate_language" }
const _hoisted_2 = { class: "google_translater" }
const _hoisted_3 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Multiselect = _resolveComponent("Multiselect")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("p", null, [
      _createElementVNode("span", { class: "cm-color" }, "Translation:")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Multiselect, {
        class: "form-control",
        placeholder: "Select a language",
        onInput: _ctx.onChangeLanguage,
        modelValue: _ctx.currentLanguage,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentLanguage) = $event)),
        options: _ctx.languages,
        searchable: true
      }, null, 8, ["onInput", "modelValue", "options"]),
      _cache[1] || (_cache[1] = _createElementVNode("i", { class: "fa fa-language" }, null, -1))
    ]),
    _withDirectives(_createElementVNode("div", { id: _ctx.translateElementId }, null, 8, _hoisted_3), [
      [_vShow, false]
    ])
  ], 512)), [
    [_vShow, !_ctx.isLoading]
  ])
}