import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "side_bar" }
const _hoisted_2 = { class: "side_card" }
const _hoisted_3 = { class: "social_links" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ShareNetwork = _resolveComponent("ShareNetwork")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[5] || (_cache[5] = _createElementVNode("h3", { class: "sairaR" }, "Share on", -1)),
      _createElementVNode("ul", _hoisted_3, [
        _createVNode(_component_ShareNetwork, {
          network: "facebook",
          url: $props.url,
          quote: $props.quote,
          hashtags: $props.hashtags,
          title: $props.title,
          description: $props.description
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("i", { class: "fa fa-facebook" }, null, -1)
          ])),
          _: 1
        }, 8, ["url", "quote", "hashtags", "title", "description"]),
        _createVNode(_component_ShareNetwork, {
          network: "twitter",
          url: $props.url,
          quote: $props.quote,
          hashtags: $props.hashtags,
          title: $props.title,
          description: $props.description
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createElementVNode("i", { class: "fa fa-twitter" }, null, -1)
          ])),
          _: 1
        }, 8, ["url", "quote", "hashtags", "title", "description"]),
        _createVNode(_component_ShareNetwork, {
          network: "skype",
          url: $props.url,
          quote: $props.quote,
          hashtags: $props.hashtags,
          title: $props.title,
          description: $props.description
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createElementVNode("i", { class: "fa fa-skype" }, null, -1)
          ])),
          _: 1
        }, 8, ["url", "quote", "hashtags", "title", "description"]),
        _createVNode(_component_ShareNetwork, {
          network: "whatsapp",
          url: $props.url,
          quote: $props.quote,
          hashtags: $props.hashtags,
          title: $props.title,
          description: $props.description
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createElementVNode("i", { class: "fa fa-whatsapp" }, null, -1)
          ])),
          _: 1
        }, 8, ["url", "quote", "hashtags", "title", "description"]),
        _createVNode(_component_ShareNetwork, {
          network: "telegram",
          url: $props.url,
          quote: $props.quote,
          hashtags: $props.hashtags,
          title: $props.title,
          description: $props.description
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createElementVNode("i", { class: "fa fa-telegram" }, null, -1)
          ])),
          _: 1
        }, 8, ["url", "quote", "hashtags", "title", "description"])
      ])
    ])
  ]))
}