import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/images/three-dot-icon.svg'
import _imports_1 from '@/assets/images/pencil.svg'
import _imports_2 from '@/assets/images/delete.svg'
import _imports_3 from '@/assets/images/report-icon.svg'
import _imports_4 from '@/assets/images/forbidden.svg'
import _imports_5 from '@/assets/images/talkative.svg'
import _imports_6 from '@/assets/images/somethingElse.svg'


const _hoisted_1 = { class: "dots_menu" }
const _hoisted_2 = { class: "dots" }
const _hoisted_3 = {
  key: 0,
  class: "report"
}
const _hoisted_4 = {
  key: 2,
  class: "report-drop"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _imports_0,
        alt: "",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleMenu()))
      })
    ]),
    (_ctx.isMenuOpen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_ctx.hasEditPermission)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "cmn_drop_btn",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onEdit()))
              }, _cache[7] || (_cache[7] = [
                _createElementVNode("img", {
                  src: _imports_1,
                  alt: "",
                  class: "mr-2"
                }, null, -1),
                _createTextVNode(" Edit ")
              ])))
            : _createCommentVNode("", true),
          (_ctx.hasEditPermission)
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                class: "cmn_drop_btn",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onDelete()))
              }, _cache[8] || (_cache[8] = [
                _createElementVNode("img", {
                  src: _imports_2,
                  alt: "",
                  class: "mr-2"
                }, null, -1),
                _createTextVNode(" Delete ")
              ])))
            : _createCommentVNode("", true),
          _createElementVNode("button", {
            class: "cmn_drop_btn main-btn",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.toggleReport()))
          }, _cache[9] || (_cache[9] = [
            _createElementVNode("img", {
              src: _imports_3,
              alt: "",
              class: "mr-2",
              width: "20px"
            }, null, -1),
            _createTextVNode(" Report ")
          ])),
          (_ctx.isReportOpen)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
                _createElementVNode("li", null, [
                  _createElementVNode("button", {
                    class: "sub-reportbtn",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onReport(_ctx.ReportReason.Pertinence)))
                  }, _cache[10] || (_cache[10] = [
                    _createElementVNode("img", {
                      src: _imports_4,
                      alt: "",
                      class: "mr-2",
                      width: "20px"
                    }, null, -1),
                    _createTextVNode(" Not Pertinent ")
                  ]))
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("button", {
                    class: "sub-reportbtn",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onReport(_ctx.ReportReason.Language)))
                  }, _cache[11] || (_cache[11] = [
                    _createElementVNode("img", {
                      src: _imports_5,
                      alt: "",
                      class: "mr-2",
                      width: "20px"
                    }, null, -1),
                    _createTextVNode(" Rough Language ")
                  ]))
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("button", {
                    class: "sub-reportbtn",
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onReport(_ctx.ReportReason.Other)))
                  }, _cache[12] || (_cache[12] = [
                    _createElementVNode("img", {
                      src: _imports_6,
                      alt: "",
                      class: "mr-2",
                      width: "20px"
                    }, null, -1),
                    _createTextVNode(" Something Else ")
                  ]))
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}