import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/images/checked.svg'
import _imports_1 from '@/assets/images/cancel.svg'
import _imports_2 from '@/assets/images/question-heading-icon.svg'


const _hoisted_1 = { class: "validate_header" }
const _hoisted_2 = { class: "card w-100" }
const _hoisted_3 = {
  class: "card-header",
  id: "headingOne"
}
const _hoisted_4 = { class: "mb-0" }
const _hoisted_5 = { class: "list-form accord_hover_icons" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { class: "topAccord_area" }
const _hoisted_9 = { class: "cmn_topic_heading" }
const _hoisted_10 = { class: "cmn_topic_heading_img" }
const _hoisted_11 = { class: "validate_questions" }
const _hoisted_12 = {
  key: 1,
  class: "title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_guide_tool_tip = _resolveComponent("guide-tool-tip")!
  const _component_user_avatar = _resolveComponent("user-avatar")!

  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass({ question_card_min_height: _ctx.isShowValidate })
      }, [
        (_ctx.isPageLoaded)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "cmn_validate_btn",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleIsShowValidate()))
            }, _toDisplayString(_ctx.isShowValidate ? "Hide" : "Validate Questions"), 1))
          : _createCommentVNode("", true),
        (_ctx.show)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["mb-5 cmn-accord-wrap accordion-validate-question", {
        'hide-card': !_ctx.isShowValidate,
        'opacity-card-show': _ctx.isShowValidateShow,
        'opacity-card-hide': !_ctx.isShowValidateHide,
      }])
            }, [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("h5", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("button", {
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onValidate(_ctx.ValidationAction.Confirm))),
                          disabled: _ctx.isLoading
                        }, _cache[3] || (_cache[3] = [
                          _createElementVNode("img", {
                            src: _imports_0,
                            alt: ""
                          }, null, -1)
                        ]), 8, _hoisted_6),
                        _createElementVNode("button", {
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onValidate(_ctx.ValidationAction.Reject))),
                          disabled: _ctx.isLoading
                        }, _cache[4] || (_cache[4] = [
                          _createElementVNode("img", {
                            src: _imports_1,
                            alt: ""
                          }, null, -1)
                        ]), 8, _hoisted_7)
                      ]),
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("p", _hoisted_9, [
                          _createElementVNode("span", _hoisted_10, [
                            _cache[5] || (_cache[5] = _createElementVNode("img", {
                              src: _imports_2,
                              alt: ""
                            }, null, -1)),
                            _createVNode(_component_guide_tool_tip, { text: "Validate the questions of the community" })
                          ]),
                          _cache[6] || (_cache[6] = _createTextVNode(" Do you like this question? "))
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_11, [
                        (_ctx.question)
                          ? (_openBlock(), _createBlock(_component_user_avatar, {
                              key: 0,
                              user: _ctx.question.users,
                              showPreview: true
                            }, null, 8, ["user"]))
                          : _createCommentVNode("", true),
                        (_ctx.question)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.question.title), 1))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ])
                ])
              ])
            ], 2))
          : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}