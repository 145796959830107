import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cmn_heading_area" }
const _hoisted_2 = { class: "heading" }
const _hoisted_3 = { class: "cm-color" }
const _hoisted_4 = { class: "small_desc" }
const _hoisted_5 = { style: {"margin-right":"50px"} }
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_translate = _resolveComponent("select-translate")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", null, [
        _cache[0] || (_cache[0] = _createElementVNode("span", { class: "cm-color" }, "Source:", -1)),
        _createTextVNode(_toDisplayString(_ctx.source), 1)
      ]),
      _createElementVNode("p", _hoisted_5, [
        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "cm-color" }, "Level:", -1)),
        _createElementVNode("img", {
          src: _ctx.topicLevelBadge,
          alt: "level",
          class: "img-fluid mr-1"
        }, null, 8, _hoisted_6),
        _createTextVNode(_toDisplayString(_ctx.levelLabel), 1)
      ]),
      _createVNode(_component_select_translate)
    ])
  ]))
}